import { render } from "./hide.svg?vue&type=template&id=50fe9f7c"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "50fe9f7c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('50fe9f7c', script)) {
    api.reload('50fe9f7c', script)
  }
  
  module.hot.accept("./hide.svg?vue&type=template&id=50fe9f7c", () => {
    api.rerender('50fe9f7c', render)
  })

}

script.__file = "src/assets/icons/networks/hide.svg"

export default script