// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BLACK */
body.dark .sidebar__logo[data-v-701ac82d] {
  fill: #FFFFFF;
}
body.dark .compact.sidebar[data-v-701ac82d] {
  background: #1c1d2e;
}
.modal[data-v-701ac82d] {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1111;
  overflow: auto;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
