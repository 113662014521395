export const OTHERS_COLOR = '#c3c3c3';

export const chartColors = [
  '#444',
  '#BD00FF',
  '#7DF89F',
  '#0044FF',
  '#FFC107',
  '#00C2FF',
  '#0ABA11',
  '#7F5EFF',
  '#008DE4',
  '#F44336',
  '#310D80',
  '#0BC0AB',
  '#F47458',
  '#004149',
  '#00DFFF',
  '#0A0039',
  '#5639E0',
  '#0A0039',
  '#5E0B4B',
  '#FF54DB',
  '#FF3D00',
  '#5E0B0B',
  '#ED4242',
  '#5E280B',
  '#FF9A7A',
  '#061342',
  '#3A5EE5',
  '#422D06',
  '#FFC459',
  '#3C5B7E',
  '#C3D6EC',
  '#005376',
  '#026573',
  '#B7F6FF',
  '#170A52',
  '#7C63F5',
  '#1C622F',
  '#D7FFE2',
  '#90337D',
  '#FFE2F9',
  '#8F2222',
  '#FFF2F2',
  '#8F3705',
  '#FFE3DA',
  '#182969',
  '#869FFF',
  '#775617',
  '#FFEDCD',
  '#59779A',
  '#E5EDF5',
  '#0070A0',
];
