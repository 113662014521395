import { render } from "./link.svg?vue&type=template&id=39593ea0"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "39593ea0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('39593ea0', script)) {
    api.reload('39593ea0', script)
  }
  
  module.hot.accept("./link.svg?vue&type=template&id=39593ea0", () => {
    api.rerender('39593ea0', render)
  })

}

script.__file = "src/assets/icons/link.svg"

export default script