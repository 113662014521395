
import closeIcon from '@/assets/icons/close-icon.svg';
import PrimaryButton from '@/components/UI/PrimaryButton';
import downloadAppIcon from '@/assets/icons/downloadApp.png';

export default {
  name: 'MobileAppModal',
  components: { PrimaryButton, closeIcon },
  emits: ['close'],
  setup() {
    const clickHandler = (link) => {
      window.location.href = link;
    };

    return { clickHandler, downloadAppIcon };
  },
};
