export const cosmosNetworks = [
  'cosmos',
  'band',
  'secret',
  'akash',
  'kava',
  'osmosis',
  'comdex',
  'juno',
  'persistence',
  'chihuahua',
  'sentinel',
  'umee',
  'stargaze',
  'rizon',
  'sifchain',
  'fetch',
  'lum',
  'cheqd',
  'injective',
  'bitsong',
  'kichain',
  'regen',
  'starname',
  'emoney',
  'iris',
  'gbridge',
  'axelar',
  'konstellation',
  'mantle',
  'evmos',
  'orai',
];
