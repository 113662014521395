
export default {
  name: 'Modal',
  props: {
    position: {
      type: String,
      default: 'fixed',
    },
    marginLeft: {
      type: String,
      default: '0',
    },
  },
};
