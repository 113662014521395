import { render } from "./close-icon-round.svg?vue&type=template&id=1c65d465"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "1c65d465"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1c65d465', script)) {
    api.reload('1c65d465', script)
  }
  
  module.hot.accept("./close-icon-round.svg?vue&type=template&id=1c65d465", () => {
    api.rerender('1c65d465', render)
  })

}

script.__file = "src/assets/icons/close-icon-round.svg"

export default script