import { render } from "./mail.svg?vue&type=template&id=409f9637"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "409f9637"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('409f9637', script)) {
    api.reload('409f9637', script)
  }
  
  module.hot.accept("./mail.svg?vue&type=template&id=409f9637", () => {
    api.rerender('409f9637', render)
  })

}

script.__file = "src/assets/icons/networks/mail.svg"

export default script