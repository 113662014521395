import { render } from "./error.svg?vue&type=template&id=080dab98"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "080dab98"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('080dab98', script)) {
    api.reload('080dab98', script)
  }
  
  module.hot.accept("./error.svg?vue&type=template&id=080dab98", () => {
    api.rerender('080dab98', render)
  })

}

script.__file = "src/assets/icons/networks/error.svg"

export default script