import { render } from "./close-icon.svg?vue&type=template&id=9c807c38"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "9c807c38"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('9c807c38', script)) {
    api.reload('9c807c38', script)
  }
  
  module.hot.accept("./close-icon.svg?vue&type=template&id=9c807c38", () => {
    api.rerender('9c807c38', render)
  })

}

script.__file = "src/assets/icons/close-icon.svg"

export default script