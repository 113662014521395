import { render } from "./info.svg?vue&type=template&id=4bd7a1ae"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "4bd7a1ae"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('4bd7a1ae', script)) {
    api.reload('4bd7a1ae', script)
  }
  
  module.hot.accept("./info.svg?vue&type=template&id=4bd7a1ae", () => {
    api.rerender('4bd7a1ae', render)
  })

}

script.__file = "src/assets/icons/networks/info.svg"

export default script