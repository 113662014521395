
import closeIcon from '@/assets/icons/close-icon.svg';
import addedIcon from '@/assets/icons/wallet-added.svg';
import PrimaryButton from '@/components/UI/PrimaryButton';

export default {
  name: 'AddressAlreadyAdded',
  components: { PrimaryButton, closeIcon, addedIcon },
  emits: ['close', 'buttonClick'],
  setup(props, { emit }) {
    const clickHandler = () => {
      emit('buttonClick');
    };

    return { clickHandler };
  },
};
