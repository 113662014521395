import { render } from "./cat-icon.svg?vue&type=template&id=5fc42c74"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "5fc42c74"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('5fc42c74', script)) {
    api.reload('5fc42c74', script)
  }
  
  module.hot.accept("./cat-icon.svg?vue&type=template&id=5fc42c74", () => {
    api.rerender('5fc42c74', render)
  })

}

script.__file = "src/assets/icons/cat-icon.svg"

export default script