
import { computed } from 'vue';
import { useStore } from 'vuex';
import Toast from './Toast';

export default {
  name: 'Toasts',
  components: { Toast },
  setup() {
    const store = useStore();
    const toasts = computed(() => store.getters['toasts/toasts']);

    const close = (id) => {
      store.dispatch('toasts/removeToast', id);
    };

    return {
      toasts,
      close,
    };
  },
};
