import { render } from "./vision.svg?vue&type=template&id=a32f1d30"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a32f1d30"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a32f1d30', script)) {
    api.reload('a32f1d30', script)
  }
  
  module.hot.accept("./vision.svg?vue&type=template&id=a32f1d30", () => {
    api.rerender('a32f1d30', render)
  })

}

script.__file = "src/assets/icons/networks/vision.svg"

export default script