import { render } from "./copy.svg?vue&type=template&id=7dfd2aab"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "7dfd2aab"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7dfd2aab', script)) {
    api.reload('7dfd2aab', script)
  }
  
  module.hot.accept("./copy.svg?vue&type=template&id=7dfd2aab", () => {
    api.rerender('7dfd2aab', render)
  })

}

script.__file = "src/assets/icons/copy.svg"

export default script