
import { markRaw, ref, computed } from 'vue';
import closeIcon from '@/assets/icons/close-icon.svg';
import linkIcon from '@/assets/icons/link.svg';
import { useI18n } from 'vue-i18n';

export default {
  name: 'Toast',
  components: { closeIcon, linkIcon },
  props: {
    type: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: [String, Error],
      default: '',
    },
    hash: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup(props) {
    const { t } = useI18n();
    const currentIcon = ref();
    import(`@/assets/icons/toasts/${props.type}.svg`).then((val) => {
      currentIcon.value = markRaw(val.default);
    });

    const txTitle = computed(() => {
      if (props.hash) {
        if (props.type === 'warning') {
          return t('transactionsSocket.failed');
        }

        if (props.type === 'success') {
          return t('transactionsSocket.completed');
        }

        return props.title;
      }

      return props.title;
    });

    return {
      currentIcon,
      txTitle,
    };
  },
};
