import { render } from "./wallet-added.svg?vue&type=template&id=e0ec9c3c"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "e0ec9c3c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('e0ec9c3c', script)) {
    api.reload('e0ec9c3c', script)
  }
  
  module.hot.accept("./wallet-added.svg?vue&type=template&id=e0ec9c3c", () => {
    api.rerender('e0ec9c3c', render)
  })

}

script.__file = "src/assets/icons/wallet-added.svg"

export default script