
import loadingIcon from '@/assets/icons/load-btn.svg';

export default {
  components: { loadingIcon },
  name: 'PrimaryButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: '#6A4BFF',
    },
    color: {
      type: String,
      default: '#FFFFFF',
    },
    hoverColor: {
      type: String,
      default: '#FFFFFF',
    },
    hoverBgColor: {
      type: String,
      default: '#1A53F0',
    },
    noDecoration: {
      type: Boolean,
      default: false,
    },
    boxShadow: {
      type: String,
      default: '0px 0px 25px rgba(106, 75, 255, 0.3)',
    },
    border: {
      type: String,
      default: 'none',
    },
    hoverBorder: {
      type: String,
      default: 'none',
    },
    dataQa: {
      type: [String, null],
      default: null,
    },
    id: {
      type: String,
    },
  },
  emits: ['click'],
  computed: {
    cssVars() {
      return {
        '--backgroundColor': this.bgColor,
        '--textColor': this.color,
        '--hoveredColor': this.hoverColor,
        '--hoverededBgColor': this.hoverBgColor,
        '--buttonShadow': this.boxShadow,
        '--buttonBorder': this.border,
        '--buttonHoverBorder': this.hoverBorder,
      };
    },
  },
};
