export default {
  types: {
    transaction: 'transaction',
    message: 'message',
    execute: 'scrt-execute',
    generateVK: 'scrt-generate-viewing-key',
    balance: 'view-scrt-balance',
  },
  messages: {
    success: 'SUCCESS',
    canceled: 'CANCELED',
    failed: 'FAILED',
  },
};
