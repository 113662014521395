import { render } from "./load-btn.svg?vue&type=template&id=6dfa702b"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "6dfa702b"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('6dfa702b', script)) {
    api.reload('6dfa702b', script)
  }
  
  module.hot.accept("./load-btn.svg?vue&type=template&id=6dfa702b", () => {
    api.rerender('6dfa702b', render)
  })

}

script.__file = "src/assets/icons/load-btn.svg"

export default script