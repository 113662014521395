
import closeIcon from '@/assets/icons/close-icon-round.svg';
import copy from '@/assets/icons/copy.svg';
import info from '@/assets/icons/networks/info.svg';
import vision from '@/assets/icons/networks/vision.svg';
import hide from '@/assets/icons/networks/hide.svg';
import mail from '@/assets/icons/networks/mail.svg';
import error from '@/assets/icons/networks/error.svg';
import { computed, ref, markRaw, watch, nextTick, onMounted } from 'vue';
import copyToClipboard from '@/helpers/copyToClipboard';
import { WALLET_TYPES } from '@/config/walletType';
import { useStore } from 'vuex';
import { getDecimalCount, cutNumberWithDecimals } from '@/helpers';

export default {
  name: 'Input',
  components: { vision, hide, copy, info, mail, error, closeIcon },
  props: {
    warningLabel: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelInfo: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    hardAutocompleteOff: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
    },
    max: {
      type: [Number, String],
      default: Infinity,
    },
    min: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: '',
    },
    showSetMax: {
      type: Boolean,
      default: false,
    },
    showErrorText: {
      type: Boolean,
      default: true,
    },
    error: {
      type: [String, Boolean],
      default: '',
    },
    icon: {
      type: [String],
    },
    withCopy: {
      type: Boolean,
    },
    text: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dataQa: {
      type: [String, null],
      default: null,
    },
    decimals: {
      type: [Number, String],
      default: 0,
    },
  },
  emits: ['update:modelValue', 'focus', 'blur', 'input', 'clear', 'iconClick'],
  setup(props, { emit }) {
    const store = useStore();
    const valueRef = ref('');
    const currentIcon = ref();
    const focusFlag = ref(false);
    const hide = ref(false);
    const isCopied = ref(false);
    const isTypeCurrency = computed(() => props.type === 'currency');
    const currencyOffset = ref(0);
    const resetAutocomplete = ref(false);

    // DOM
    const currencyTextRef = ref(null);
    const inputRef = ref(null);
    const textWidth = ref(0);
    const inputPaddingLeft = ref(0);
    const inputWidth = ref(0);

    if (props.icon) {
      if ([WALLET_TYPES.LEDGER, WALLET_TYPES.TREZOR].includes(props.icon)) {
        import(`@/assets/icons/networks/hardware-dot.svg`).then((val) => {
          currentIcon.value = markRaw(val.default);
        });
      } else if ([WALLET_TYPES.PRIVATE_KEY].includes(props.icon)) {
        import(`@/assets/icons/networks/private-dot.svg`).then((val) => {
          currentIcon.value = markRaw(val.default);
        });
      } else if ([WALLET_TYPES.ONE_SEED].includes(props.icon)) {
        import(`@/assets/icons/networks/oneseed-dot.svg`).then((val) => {
          currentIcon.value = markRaw(val.default);
        });
      } else if (props.icon === WALLET_TYPES.KEPLR) {
        import(`@/assets/icons/networks/keplr-dot.svg`).then((val) => {
          currentIcon.value = markRaw(val.default);
        });
      } else if (props.icon === WALLET_TYPES.LEAP) {
        import(`@/assets/icons/networks/leap-dot.svg`).then((val) => {
          currentIcon.value = markRaw(val.default);
        });
      } else {
        import(`@/assets/icons/networks/${props.icon}.svg`).then((val) => {
          currentIcon.value = markRaw(val.default);
        });
      }
    }

    const inputType = computed(() => {
      if (props.type === 'password') {
        return hide.value ? 'text' : 'password';
      } else if (props.type === 'currency') {
        return 'text';
      }

      return props.type;
    });

    // when focus, placeholder = ''
    const dynamicPlaceholder = computed(() => {
      if (focusFlag.value) return '';

      if ([WALLET_TYPES.LEDGER, WALLET_TYPES.TREZOR].includes(props.icon)) {
        return 'Citadel Hardware';
      }

      if ([WALLET_TYPES.ONE_SEED].includes(props.icon)) {
        return 'Citadel One-Seed';
      }

      if ([WALLET_TYPES.PRIVATE_KEY].includes(props.icon)) {
        return 'Citadel Private-Key';
      }

      if ([WALLET_TYPES.KEPLR].includes(props.icon)) {
        return 'Citadel Keplr';
      }

      return props.placeholder;
    });

    const placeholderShown = computed(() => {
      return !focusFlag.value && !!dynamicPlaceholder.value && !valueRef.value;
    });

    watch(valueRef, (val) => {
      valueRef.value = prepareRealValue(val);

      emit('update:modelValue', prepareModelValue(valueRef.value));
      setCurrencyOffset();
    });

    // update value from modelValue changed outside
    watch(
      () => props.modelValue,
      (val) => {
        valueRef.value = prepareRealValue(val);
      }
    );

    // update value from :value prop
    watch(
      () => props.value,
      (val) => {
        valueRef.value = prepareRealValue(val);
      }
    );

    watch(placeholderShown, () => {
      setCurrencyOffset();
    });

    const prepareRealValue = (value) => {
      if (isTypeCurrency.value) {
        return (
          value
            .toString()
            // remove spaces
            .replace(/\s+/g, '')
            .replace(/[БбЮю]/, '.')
            .replace(',', '.')
            // only number
            .replace(/[^.\d]+/g, '')
            // remove extra 0 before decimal
            .replace(/^0+/, '0')
            // remove extra dots
            // eslint-disable-next-line no-useless-escape
            .replace(/^([^\.]*\.)|\./g, '$1')
        );
      }

      return value;
    };

    const prepareModelValue = (value) => {
      if (isTypeCurrency.value) {
        return value.replace(/\.$/gm, '');
      }

      return value;
    };

    const setCurrencyOffset = () => {
      nextTick(() => {
        textWidth.value = currencyTextRef.value.clientWidth;
        currencyOffset.value =
          inputPaddingLeft.value +
          (textWidth.value >= inputWidth.value
            ? inputWidth.value
            : textWidth.value);
      });
    };

    onMounted(() => {
      valueRef.value = prepareRealValue(props.value || props.modelValue);

      const inputComputedStyles = window.getComputedStyle(inputRef.value);

      inputPaddingLeft.value = parseInt(inputComputedStyles.paddingLeft);
      inputWidth.value =
        inputRef.value.clientWidth -
        inputPaddingLeft.value -
        parseInt(inputComputedStyles.paddingRight);

      setCurrencyOffset();

      if (props.autofocus) {
        inputRef.value.focus();
      }

      if (
        props.type === 'password' &&
        store.getters['profile/rememberPassword']
      ) {
        resetAutocomplete.value = store.getters['profile/rememberPassword'];
      }
    });

    const inputHandler = () => {
      if (props.decimals && getDecimalCount(valueRef.value) > +props.decimals) {
        const validValue = cutNumberWithDecimals(
          valueRef.value,
          +props.decimals
        );
        valueRef.value = validValue;
        return;
      }
      emit('input', prepareModelValue(valueRef.value));
    };

    const onFocusHandler = (e) => {
      focusFlag.value = true;
      emit('focus', e);
    };

    const onBlurHandler = (e) => {
      focusFlag.value = false;
      emit('blur', e);
    };

    const copyValue = () => {
      copyToClipboard(props.modelValue);
      isCopied.value = true;

      setTimeout(() => {
        isCopied.value = false;
      }, 1500);
    };

    const clearInput = () => {
      valueRef.value = '';
      emit('input', prepareModelValue(valueRef.value));
      emit('clear', prepareModelValue(valueRef.value));
    };

    const setMax = () => {
      valueRef.value = props.max.toString();
      emit('input', prepareModelValue(valueRef.value));

      nextTick(() => {
        inputRef.value.focus();
      });
    };

    return {
      currencyTextRef,
      inputRef,
      valueRef,
      hide,
      isCopied,
      currentIcon,
      dynamicPlaceholder,
      placeholderShown,
      focusFlag,
      inputType,
      isTypeCurrency,
      currencyOffset,
      inputPaddingLeft,
      textWidth,
      inputWidth,
      inputHandler,
      onFocusHandler,
      onBlurHandler,
      setMax,
      copyValue,
      clearInput,
      resetAutocomplete,
    };
  },
};
