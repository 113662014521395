export const ethCoinColors = {
  arbitrum: '#28a0f0',
  optimism: '#ff0420',
};

export const coinColors = {
  GBRIDGE: '#002FA7',
  XKI: '#0000ff',
  ALPACA: '#32c980',
  IOST: '#000000',
  CMDX: '#fe4350',
  STARS: '#c2dd87',
  TUSD: '#195aff',
  DAI: '#fbbe41',
  WETH: '#f22382',
  ICX: '#2da2a4',
  AKT: '#ed3524',
  AVAX: '#e84142',
  AAVE: '#2EBAC6',
  XCT: '#5030A0',
  CRE: '#ffa939',
  CRO: '#002daa',
  CTK: '#00c5c5',
  SCRT: '#151a20',
  MNTL: '#fabd29',
  REGEN: '#52b775',
  NGM: '#002e33',
  LUM: '#101f7c',
  DVPN: '#3A80F7',
  DYDX: '#161523',
  '1INCH': '#1b314f',
  EVMOS: '#ee4d30',
  ROWAN: '#caa839',
  ETH: '#716b94',
  HUAHUA: '#f9d22e',
  OSMO: '#1a154f',
  JUNO: '#FF7B7C',
  CETH: '#00D395',
  CUSDT: '#00D395',
  KAVA: '#FF5722',
  ATOLO: '#32d8cd',
  IOV: '#5b68b0',
  BNB: '#F3BA2F',
  BUSD: '#f0b90b',
  ADA: '#0D1E30',
  DOGE: '#C2A633',
  XRP: '#231F20',
  USDT: '#26A17B',
  DOT: '#E5007D',
  BCH: '#8DC351',
  LTC: '#1C75BC',
  LINK: '#2A5ADA',
  UNI: '#FF007A',
  THETA: '#2AB8E6',
  XLM: '#08080B',
  SOL: '#00BDF9',
  FIL: '#42C1CA',
  VET: '#15BDFF',
  ATOM: '#6f7390',
  TRX: '#D9012C',
  EOS: '#141C2D',
  ETC: '#328332',
  MKR: '#1ABC9C',
  MATIC: '#8247E5',
  XTZ: '#A6E000',
  NEO: '#58BF00',
  SHIB: '#ebaf5e',
  FTT: '#00D572',
  ALGO: '#00C2FF',
  CHZ: '#00A3D8',
  XEC: '#444444',
  KSM: '#000000',
  RVN: '#000000',
  BTG: '#EBA810',
  HOT: '#FF5722',
  LUNA: '#094D90',
  CAKE: '#F7931A',
  DASH: '#008DE4',
  ZIL: '#49c1bf',
  CRV: '#0073FF',
  SUSHI: '#D11FFF',
  ONE: '#313131',
  ZEC: '#ECB244',
  EWT: '#00AA5B',
  HNT: '#4C4C4C',
  GRT: '#674FFF',
  KLAY: '#DB202C',
  FTM: '#525252',
  MANA: '#FF2D55',
  BAT: '#FF5000',
  NEXO: '#1FAAEB',
  INJ: '#00D5FD',
  AMP: '#FFDC6B',
  ZEN: '#00BCFF',
  WAVES: '#015EFE',
  XMR: '#F60',
  OGN: '#FF622E',
  YFI: '#006AE3',
  HBAR: '#4C6B8B',
  SNX: '#2E6AFF',
  CEL: '#000000',
  BAL: '#2E9AD0',
  RVX: '#EFF5F5',
  REV: '#231F20',
  STX: '#000000',
  LRC: '#2ABD9B',
  VGX: '#00D395',
  OCEAN: '#00A6FF',
  UST: '#00C0CC',
  NPXS: '#F5A623',
  GNO: '#00ADEF',
  DGB: '#006AC9',
  AR: '#C7065B',
  ANT: '#2EAEBF',
  SNT: '#5B6DEE',
  ICP: '#2B6AD2',
  FLOW: '#238DFB',
  REP: '#603E99',
  USDC: '#2775CA',
  REN: '#00AEEF',
  KNC: '#31CB9E',
  CKB: '#0E222E',
  KDA: '#2C2D86',
  SKL: '#FF974A',
  ZRX: '#3E5CE3',
  SAND: '#56C5FF',
  FIT: '#F9A11B',
  EGG: '#F15A22',
  GUSD: '#68B6F3',
  LINA: '#EBB900',
  YFII: '#487AF2',
  NEX: '#212121',
  CTSI: '#00BBFF',
  BNT: '#000D2B',
  HYDRO: '#1666AF',
  AGI: '#6916FF',
  WAX: '#F6D12C',
  AGRS: '#2F9464',
  GREEN: '#71B646',
  UMA: '#FF5C5C',
  SXP: '#FF315E',
  WTC: '#8200FF',
  KCS: '#0093DD',
  ELA: '#2B2B2B',
  CND: '#383939',
  CHI: '#E899E3',
  BTM: '#504C4C',
  CVC: '#3AB03E',
  PPT: '#4E7FB2',
  ECKER: '#E9B004',
  BAND: '#516FF0',
  NWC: '#F8AC1D',
  XEM: '#67B2E3',
  GVT: '#16B9AD',
  WBA: '#F6AE1C',
  TWT: '#FF3E3E',
  MCO: '#002D72',
  XIN: '#292B31',
  MET: '#D6AD27',
  WICC: '#574B8F',
  DX: '#1A1A1A',
  BIX: '#1A1A1A',
  CHSB: '#4A4A4A',
  OMG: '#1FDE8B',
  TEL: '#14C8FF',
  ATRI: '#FFB642',
  SYS: '#0082C6',
  COCOS: '#000000',
  BURGER: '#F2925C',
  SRM: '#314CFF',
  UBT: '#4963E3',
  OXT: '#000000',
  BTMX: '#F01869',
  DIA: '#4DA7F5',
  ALN: '#059FED',
  JST: '#FF6300',
  AMPL: '#F4D293',
  PHA: '#644BFF',
  HOLD: '#E6FF80',
  WGR: '#8400FF',
  VRA: '#231F20',
  RAI: '#454545',
  COTI: '#2D2E2F',
  BIDR: '#225F27',
  FARM: '#1C1C1C',
  YOP: '#D10FFF',
  ECC: '#CC1047',
  TRAC: '#17181A',
  UNN: '#42574E',
  DNT: '#2F5DCB',
  GT: '#0D0D0D',
  IDLE: '#247C8D',
  SNGLS: '#B30D23',
  PIVX: '#777777',
  FET: '#1D1D1D',
  AUDR: '#5A86A5',
  SNTVT: '#5B3AB4',
  NOIA: '#1F4179',
  BORA: '#303C42',
  GAME: '#2D475B',
  YLD: '#FFCD27',
  GLM: '#FFCC02',
  AUTO: '#14C8FF',
  LTA: '#005492',
  COLX: '#82C8F7',
  HPT: '#627eea',
  SAI: '#BBB',
  TRB: '#3369E6',
  MDT: '#1E2C45',
  VTHO: '#2F313D',
  PNT: '#00DAD9',
  SALT: '#0A0A0A',
  WABI: '#7821A4',
  OLT: '#433151',
  PIZZA: '#D42A2B',
  POND: '#39ADFA',
  GNY: '#FFB600',
  ZMN: '#FF9E1B',
  NMR: '#F44A7D',
  BPLC: '#CA0289',
  COIL: '#0976C2',
  TROY: '#31C2FF',
  TRND: '#FE5B5B',
  LIT: '#1F1F1F',
  TAAS: '#002342',
  ROOBEE: '#DE3535',
  DAPS: '#1C1C1C',
  TNC: '#1C60FF',
  LGO: '#FF4A34',
  XDB: '#D11FFF',
  AUC: '#A40B12',
  UKG: '#AF2A32',
  ADP: '#3B5998',
  RDN: '#2A2A2A',
  WLK: '#1983FF',
  HMQ: '#005CFF',
  RECOVER: '#032B52',
  SKM: '#6868FF',
  NKN: '#14C8FF',
  RUFF: '#00ACFF',
};
